import { useRef, useEffect, useState } from 'react';
import HTMLFlipBook from 'react-pageflip';
import styled from 'styled-components';

const images = [
  "or_1-0.jpeg",
  "or_1-1.jpeg",
  "or_2-0.jpeg",
  "or_2-1.jpeg",
  "or_3-0.jpeg",
  "or_3-1.jpeg",
  "or_4-0.jpeg",
  "or_4-1.jpeg",
  "or_5-0.jpeg",
  "or_5-1.jpeg",
  "or_6-0.jpeg",
  "or_6-1.jpeg",
  "or_7-0.jpeg",
  "or_7-1.jpeg",
  "or_8-0.jpeg",
  "or_8-1.jpeg",
  "or_9-0.jpeg",
  "or_9-1.jpeg",
  "or_10-0.jpeg",
  "or_10-1.jpeg",
  "or_11-0.jpeg",
  "or_11-1.jpeg",
  "or_12-0.jpeg",
  "or_12-1.jpeg",
  "or_13-0.jpeg",
  "or_13-1.jpeg",
  "or_14-0.jpeg",
  "or_14-1.jpeg",
  "or_15.jpeg",
];

const PageDiv = styled.div`
  background-color: gray;
  background-image: url(${props => props.file});
  background-size: cover;
  width: 100%;
  height: auto;
`;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


function MyBook(children, props) {
  const book = useRef();
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if(process.env.REACT_APP_AUTO) {
      console.log("Running App in AutoFlip Mode")
      const intervalId = setInterval(() => book.current.pageFlip().flipNext(), 3000)

      return () => clearInterval(intervalId); //This is important
    }
  }, [book])

  return (
    <>
      <HTMLFlipBook
        ref={book}
        width={width}
        height={width}
        size="stretch"
      >
        <PageDiv className="page page-cover" file={`${process.env.PUBLIC_URL}/or/${images[0]}`}/>
        <PageDiv className="page page-cover" file={`${process.env.PUBLIC_URL}/or/${images[1]}`}/>
        {images.slice(2).map((img, id) => {
          return(
              <PageDiv className="page-content" width={width} file={`${process.env.PUBLIC_URL}/or/${img}`}/>
          );
        })}
      </HTMLFlipBook>
    </>
  );
}

export default MyBook;
